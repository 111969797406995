.progress {
  background-color: #fff;
  border-radius: 50%;
  height: 100px;
  position: absolute;
  width: 100px;

  svg {
    height: 76px;
    left: 12px;
    position: absolute;
    top: 12px;
    transform: rotate(-90deg);
    width: 76px;
  }

  .dash-path {
    transition: stroke-dashoffset 0.3s ease-in-out;
  }

  .number,
  .label {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
  }

  .number {
    bottom: 45px;
    font-size: 14px;
  }

  .label {
    bottom: 40px;
    font-size: 7px;
  }
}

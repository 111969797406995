.spinner {
  font-size: 48px;
  margin: 0;
  text-align: center;

  .svg-in {
    animation: spinAnimation 1.4s infinite linear;
    transform-origin: 50%;
  }
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

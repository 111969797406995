@import '~styles/variables';

.footer-list {
  margin: 0;
  padding: 0;
}

.footer-item {
  line-height: 32px;
  list-style: none;
  margin: 0;
  padding: 0;

  // this rule is necessary to override "all links render brand-green" style
  a:-webkit-any-link { /* stylelint-disable-line */
    color: $charcoal-gray;
  }

  a:hover {
    color: $brand-green;
    text-decoration: underline;
  }
}

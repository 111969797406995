.q-card {
  position: relative;

  .q-card-icon {
    left: 24px;
    position: absolute;
    top: 24px;
  }

  .q-card-content {
    margin-left: 80px;
  }
}

@import '~styles/variables';
@import '~styles/typography';

.missing-school-email {
  @extend %link-text;
  margin-top: 20px;
  text-decoration: none;
}

.school-card {
  border-radius: 8px;
  box-shadow: 0 0 6px 2px rgba(64, 64, 65, 0.08);
  display: flex;
  margin: 28px 0 8px;
  padding: 20px 24px;
}

.school-info {
  padding-right: 62px;
}

.school-name {
  color: $charcoal-gray;
  margin-bottom: 8px;
}

.school-address {
  color: $medium-gray;
  font-size: 12px;
  line-height: 16px;
}

.delete-button {
  align-items: center;
  color: $brand-green;
  cursor: pointer;
  display: flex;
  text-align: center;
}

.distict-school-selections {
  max-width: 900px;
  text-align: center;
}

.complete-number-selected-schools,
.number-selected-schools {
  margin: 40px 0 20px;
}

.complete-number-selected-schools {
  color: $brand-green;
}

.number-selected-schools {
  color: $medium-gray;
}

.chips-stack {
  flex-direction: row;
  max-width: 875px;
  text-align: center;
}

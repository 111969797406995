@import '~styles/variables';
@import '~styles/patterns/sticky_variables';
@import '~styles/mixins';

.q-head {
  background-color: $white;
  padding: 10px 0;

  .q-head-content {
    margin: 0 auto;
    width: $content-width;
  }
}

@include breakpoint(md-down) {
  .q-head {
    padding-left: 82px;

    .q-head-content {
      width: inherit;
    }
  }
}

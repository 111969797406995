@import '~styles/variables';

.header {
  border: 1px solid $light-gray;
  border-width: 0 0 1px;
  height: $header-height;

  .logo-type {
    left: 25px;
    top: 15px;
  }

  .header-contact {
    top: 20px;
    width: unset;
  }
}

@import 'variables';
@import 'mixins';
@import 'typography';
@import '~@classkick/pkg-svgin/dist/_index.css';

@import 'patterns/sticky';

/*********************
Resets
*********************/
html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

/*********************
States
*********************/
.hidden {
  visibility: hidden;
}

em {
  font-style: normal;
  font-weight: 500;
}

hr {
  border: 1px solid $light-gray;
  border-width: 1px 0 0;
  margin: 16px 0 18px;
}

/*********************
Containers
*********************/
.center-text {
  @include text-alignment(center);
}

.left-text {
  @include text-alignment(left);
}

.right-text {
  @include text-alignment(right);
}

.flex-center {
  @include display-flex;
  @include justify-content(center);
  height: 100%;
}

.flex-start {
  @include display-flex;
  @include justify-content(flex-start);
  height: 100%;
}

.flex-end {
  @include display-flex;
  @include justify-content(flex-end);
  height: 100%;
}



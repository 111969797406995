.q-faq {
  padding: 88px 0 100px;

  .title {
    text-align: center;
  }

  .questions {
    margin: 0 auto;
    max-width: 988px;
    padding-left: 24px;
  }
}

@import 'src/styles/mixins';

.slide-target {
  padding-top: 150px;
  position: absolute;
  width: 100%;

  .step-title {
    display: flex;
    margin-left: -64px;
  }

  .btn-back {
    margin-right: 16px;
    top: -8px;
  }

  .btn-back .svg-in {
    top: 1px;
  }
}

@include breakpoint(sm-down) {
  .slide-target {
    .step-title {
      margin-left: 0;
    }
  }
}

@import '~styles/variables';

.landingpage-header-links {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-around;
  margin-top: 0.75em;

  .landingpage-menu-icon {
    color: $charcoal-gray;
  }
}

.landingpage-links-menu {
  /* stylelint-disable-next-line */
  a:-webkit-any-link {
    color: $charcoal-gray;
  }
}

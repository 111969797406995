@import '~styles/variables';
@import 'src/styles/mixins';

.payment-detail {
  background-color: $white;
  padding: 72px 0 100px;
  text-align: center;
}

@include breakpoint(md-down) {
  .payment-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}


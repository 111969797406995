@import '~styles/variables';

.toolkick {
  color: $medium-gray;
  display: inline-block;
  position: relative;

  .svg-in {
    font-size: 24px; // if relative sizing becomes preferred, try 2em
    left: 8px; // if relative sizing becomes preferred, try 0.3em
    position: absolute;
    top: -14px; // if relative sizing becomes preferred, try -0.583em
  }
}

@import '~styles/variables';
@import '~styles/mixins';

//move height and padding to it's related styles inline in ContactUsButton.tsx, L46

.quote-contactus-form {
  height: 760px;
  padding: 0 60px;

  .contactus-form-sendbtn {
    display: flex;
    justify-content: flex-end;
    padding: 58px 0 0;
    position: relative;
  }

  .customized-dialog .dialog-title {
    margin-bottom: unset;
    padding: 64px 0 8px;
  }
}





$sticker-rotation: 12.45deg;

.art {
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  position: relative;
}

.art-sticker {
  position: absolute;
  transform: rotate($sticker-rotation);
  transition: transform 0.5s ease-in-out;

  &.pos-br {
    bottom: -30px;
    right: -32px;
  }

  &.pos-tl {
    left: 0;
    top: -49px;
    transform: rotate(-$sticker-rotation);
  }
}

.benefit:hover .art-sticker {
  transform: rotate(-$sticker-rotation);

  &.pos-tl {
    transform: rotate($sticker-rotation);
  }
}

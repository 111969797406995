.slide-target {
  opacity: 0;
  pointer-events: none;
}


.slide-appear-done,
.slide-enter-done {
  pointer-events: initial;
}

.slide-appear-active,
.slide-enter-active,
.slide-exit-active {
  transition-duration: 300ms;
  transition-property: opacity transform;
  transition-timing-function: ease-in-out;
}

.slide-exit,
.slide-exit-active,
.slide-exit-done {
  pointer-events: none;
}

.direction-forward {
  .slide-target,
  .slide-appear,
  .slide-enter {
    opacity: 0;
    transform: translate(200px, 0) scale(0.8);
  }

  .slide-appear-active,
  .slide-enter-active,
  .slide-appear-done,
  .slide-enter-done,
  .slide-exit {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  .slide-exit-active,
  .slide-exit-done {
    opacity: 0;
    transform: translate(-200px, 0) scale(0.8);
  }
}

.direction-back {
  .slide-target,
  .slide-appear,
  .slide-enter {
    opacity: 0;
    transform: translate(-200px, 0) scale(0.8);
  }

  .slide-appear-active,
  .slide-enter-active,
  .slide-appear-done,
  .slide-enter-done,
  .slide-exit {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  .slide-exit-active,
  .slide-exit-done {
    opacity: 0;
    transform: translate(200px, 0) scale(0.8);
  }
}

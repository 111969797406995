@import '~styles/variables';

.canceled-page {
  height: calc(100vh - #{$header-height});
}

// Styles for quote modals

.quote-modal-content {
  display: block;
}

.quote-modal-button {
  float: right;
}

/*********************
Colors
*********************/
$alert-red: #ff6c6c;
$alert-yellow: #ffde78;
$brand-gray: #6d6e70;
$brand-green: #97d54a;
$charcoal-gray: #404041;
$hover-gray: #f7f7f7;
$hover-green: #81c241;
$light-gray: #e5e5e5;
$medium-gray: #b8babc;
$white: #fff;

/*********************
Fonts
*********************/
$gotham-rounded: Gotham Rounded, 'Varela Round', sans-serif;

$header-height: 88px;

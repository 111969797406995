@mixin text-alignment($direction) {
  display: block;
  text-align: $direction;
}

@mixin display-flex() {
  display: flex;
}

@mixin display-inline-flex() {
  display: inline-flex;
}

@mixin flex-flow($direction, $wrap) {
  -webkit-box-flex-flow: $direction $wrap;
  flex-flow: $direction $wrap;
}

@mixin justify-content($position) {
  -webkit-box-justify-content: $position;
  justify-content: $position;
}

@mixin align-items($position) {
  align-items: $position;
}

@mixin flex-row($verticalAlign, $justify, $wrap) {
  @include display-flex();
  @include flex-flow(row, $wrap);
  @include justify-content($justify);
  @include align-items($verticalAlign);
}

@mixin flex-column($horizontalAlign, $justify, $wrap) {
  @include display-flex();
  @include flex-flow(column, $wrap);
  @include justify-content($justify);
  @include align-items($horizontalAlign);
}

/*********************
Breakpoints
*********************/
@mixin breakpoint($point) {
  @if $point == sm-down {
    @media screen and (max-width: 600px) {
      @content ;
    }
  } @else if $point == md-down {
    @media screen and (max-width: 900px) {
      @content ;
    }
  } @else if $point == lg-down {
    @media screen and (max-width: 1200px) {
      @content ;
    }
  } @else if $point == xs-only or $point == xs-down {
    @media screen and (max-width: 599px) {
      @content ;
    }
  } @else if $point == sm-only {
    @media screen and (min-width: 600px) and (max-width: 899px) {
      @content ;
    }
  } @else if $point == md-only {
    @media screen and (min-width: 900px) and (max-width: 1199px) {
      @content ;
    }
  } @else if $point == lg-only {
    @media screen and (min-width: 1200px) and (max-width: 1535px) {
      @content ;
    }
  } @else if $point == xl-only or $point == xl-up {
    @media screen and (min-width: 1536px) {
      @content ;
    }
  } @else if $point == xs-up {
    @media screen and (min-width: 1px) {
      @content ;
    }
  }@else if $point == sm-up {
    @media screen and (min-width: 600px) {
      @content ;
    }
  } @else if $point == md-up {
    @media screen and (min-width: 900px) {
      @content ;
    }
  } @else if $point == lg-up {
    @media screen and (min-width: 1200px) {
      @content ;
    }
  }
}

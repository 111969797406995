@import '~styles/variables';

.logo-type {
  .type {
    color: #404041;
    font-size: 0.45em;
    font-weight: 400;
    left: -0.07em;
    position: relative;
    top: -0.5em;
  }
}

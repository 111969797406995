@import '~styles/variables';

.wizard {
  position: relative;

  .progress {
    left: 50%;
    margin-left: -50px;
    position: absolute;
    top: -50px;
  }

  .error-message {
    color: $alert-red;
    padding-top: 3em;
    text-align: center;
  }
}

@import 'variables';

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 700;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Bold.woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Bold.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Bold.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 700;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BoldItal.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BoldItal.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BoldItal.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 500;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Medium.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Medium.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Medium.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 500;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-MedItal.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-MedItal.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-MedItal.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  src:  url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Book.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Book.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Book.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 400;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 300;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Light.woff2') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Light.woff') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Light.otf') format('otf');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 300;
  src: url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.otf') format('woff2'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.otf') format('woff'),
    url('https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-BookItal.otf') format('otf');
}

html {
  // If standard browser font-size at 100% === 16px, then 62.5% === 10px
  // By setting this here, we can use "rem" units that are easier to convert to "px"
  // e.g. 1rem === 10px, 1.5rem === 15px, 2rem === 20px
  font-size: 62.5%;
}

h1,
primary-header {
  color: $charcoal-gray;
  font-family: 'Gotham Rounded';
  font-size: 5.4rem;
  font-weight: 500;
}

h2,
.secondary-header {
  color: $charcoal-gray;
  font-family: $gotham-rounded;
  font-size: 4.2rem;
  font-weight: 500;
}

h3,
.tertiary-header {
  color: $charcoal-gray;
  font-family: $gotham-rounded;
  font-size: 3.2rem;
  font-weight: 500;
}

h4,
.quaternary-header {
  color: $charcoal-gray;
  font-family: $gotham-rounded;
  font-size: 2.2rem;
  font-weight: 500;
}

h5,
.quinary-header {
  color: $charcoal-gray;
  font-family: $gotham-rounded;
  font-size: 1.8rem;
  font-weight: 500;
}

body,
%body {
  font-family: $gotham-rounded;
  font-size: 1.6rem;
  font-weight: 400;
}

.body-subtext {
  font-family: $gotham-rounded;
  font-size: 1.2rem;
  font-weight: 400;
}

.link-text,
%link-text {
  @extend %body;
  color: $brand-green;

  &:hover {
    color: $hover-green;
  }
}

@import '~styles/variables';

.contact-us-details {
  font-weight: 200;
  text-align: center;
}

.contact-email {
  color: $brand-green;
  text-decoration: none;
}

a:visited {
  color: $brand-green;
}

@import '~styles/variables';

.contact-modal-content {
  margin-bottom: 32px;
}

.contact-modal-details {
  text-align: center;
}

.contact-modal-email {
  color: $brand-green;
  text-decoration: none;
}

a:visited {
  color: $brand-green;
}

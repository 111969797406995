
@import '~styles/variables';

.renewal {
  margin: 0 auto;
  max-width: 80%;
  text-align: left;

  .q-summary {
    display: inline-block;
    margin-bottom: 32px;
    margin-top: -1em;
  }

  .fine-print {
    color: #666;
    font-size: smaller;
    line-height: 2em;
  }
}

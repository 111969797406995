@import '~styles/variables';
@import 'src/styles/mixins';

.dialog-title {
  margin-top: 20px;
  text-align: center;
}

.dialog-content {
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  .payment-detail {
    margin-bottom: 67px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0 0 10px;
  }
}

@import 'src/styles/variables';
@import 'src/styles/mixins';

// disabling because styleLint doesn't like Mui's capitalization in the class name.
.MuiCard-root.hero-card { /* stylelint-disable-line */
  border-radius: 20px;
}

.MuiTypography-root.hero-card-title { /* stylelint-disable-line */
  font-size: 24px;
  line-height: 24px;
}

.MuiTypography-root.hero-card-sub { /* stylelint-disable-line */
  padding-top: 12px;
}

.hero-card-price-container {
  align-items: flex-end;
  display: flex;
}

.MuiTypography-root.hero-card-price { /* stylelint-disable-line */
  align-self: flex-end;
  display: inline-block;
  line-height: 36px;
  padding-top: 40px;
}

.MuiTypography-root.hero-card-monthly { /* stylelint-disable-line */
  align-self: flex-end;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
}

.MuiTypography-root.hero-card-split { /* stylelint-disable-line */
  line-height: 16px;
  padding-top: 14px;
}

.MuiTypography-root.hero-card-note { /* stylelint-disable-line */
  margin-top: 24px;
}

.card-button-box {
  width: fit-content;
}

.MuiButton-root.hero-card-button { /* stylelint-disable-line */
  margin-bottom: 40px;
  padding: 0;
  text-transform: none;

  &:visited {
    color: $white;
  }
}

.MuiButton-root.MuiButton-outlined { /* stylelint-disable-line */
  &:visited {
    color: $brand-green;
  }
}

.MuiTypography-root.flag-text { /* stylelint-disable-line */
  color: $white;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  padding-top: 16px;
}

.card-graphic-container {
  float: right;
  height: 116px;
  position: relative;
  right: 20px;
  width: 80px;
}

.flag {
  height: 50px;
  left: 15px;
  margin-top: 6px;
  position: relative;
  width: 50px;
}
//Disabling because stylelint doesn't like this pseudo element notation.
.card-graphic-container:after { /* stylelint-disable-line */
  border-bottom: 20px solid $white;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  bottom: 0;
  content: ' ';
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
}

@include breakpoint('xs-up') {
  .MuiTypography-root.hero-card-price { /* stylelint-disable-line */
    font-size: 24px;
    line-height: 36px;
    padding-top: 40px;
  }
}


@include breakpoint('sm-down') {
  .card-graphic-container {
    float: none;
    height: 116px;
    left: 100px;
    position: relative;
    width: 80px;
  }

  .flag {
    height: 50px;
    left: 15px;
    position: relative;
    width: 50px;
  }

  .MuiTypography-root.hero-card-split { /* stylelint-disable-line */
    padding-top: 8px;
  }

  .MuiTypography-root.hero-card-note { /* stylelint-disable-line */
    margin-top: 30px;
  }

  .MuiButton-root.hero-card-button { /* stylelint-disable-line */
    margin-bottom: 28px;
  }
}

@include breakpoint('sm-up') {
  .MuiTypography-root.hero-card-price { /* stylelint-disable-line */
    font-size: 40px;
    line-height: 36px;
    padding-top: 40px;
  }
}

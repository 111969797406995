@import '~styles/variables';
@import 'src/styles/mixins';

@include breakpoint(md-only) {
  .magnifying-glass {
    display: none;
  }
}

@include breakpoint(sm-only) {
  .magnifying-glass {
    display: none;
  }
}

@include breakpoint(xs-only) {
  .magnifying-glass {
    display: none;
  }
}

@import '~styles/variables';
@import 'src/styles/mixins';

.q-summary {
  margin-bottom: 32px;
  margin-top: -120px;

  .per-year {
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .quote-line-items {
    margin: 34px 0;
  }

  .purchase-button {
    color: $white;
    width: 100%;
  }

  .new-quote-button {
    margin-bottom: 8px;
    text-align: center;
  }
}

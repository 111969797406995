@import '~styles/variables';

.quote-status {
  .links {
    margin-top: 64px;
  }

  a:hover {
    text-decoration: underline;
  }

  // decoration between links
  a + a::before {
    color: $charcoal-gray;
    content: '•';
    display: inline-block;
    margin: 0 5px;
  }
}

@import '~styles/patterns/sticky_variables';
@import 'src/styles/mixins';
@import 'src/styles/variables';

/*
This file defines global styles for the layout structure of several similar pages:
Quote, PO Checkout, CC Checkout

.sticky-page is the full-width page
.sticky-content is the narrower content
.sticky-sticky is the block that stays stuck on screen
.sticky-card is one-or-many pieces of content

It assumes the following DOM structure:
<1 .sticky-page>
  <2 .sticky-content>
    <3 .sticky-sticky />
    <4 .sticky-card />
    (...as many cards as you'd like)
    <4 .sticky-card />
  </2>
</1>
*/

.sticky-page {
  background-color: $hover-gray;

  .sticky-content {
    margin: 0 auto;
    padding: 24px 0;
    width: $content-width;
  }

  .sticky-card {
    margin-bottom: 32px;
    width: 60%;
  }

  .sticky-sticky {
    float: right;
    position: sticky;
    top: 50px;
    width: 35%;
  }
}

@include breakpoint(md-down) {
  .sticky-page {
    .sticky-content {
      padding: 24px 82px;
      width: 100%;
    }

    .sticky-card {
      width: 100%;
    }

    .sticky-sticky {
      float: none;
      margin: 32px 0;
      position: static;
      width: 100%;
    }
  }
}

@include breakpoint(xs-down) {
  .sticky-page {
    .sticky-content {
      padding: 0 32px;
      width: 100%;
    }
  }
}

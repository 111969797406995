.email-quote-form {
  height: 600px;
  width: 600px;

  .email-quote-form-button {
    display: flex;
    justify-content: flex-end;
    padding: 48px 0 27px;
    position: relative;
  }
}

@import '~styles/variables';
@import 'src/styles/mixins';

.q-footer {
  padding: 64px 124px 124px;

  /* stylelint-disable selector-class-pattern */
  .MuiLink-root {
    // we didn't choose this name
    color: $charcoal-gray;
  }
}

@include breakpoint(md-only) {
  .q-footer {
    padding-left: 270px;
  }
}

@include breakpoint(sm-only) {
  .q-footer {
    padding-left: 250px;
  }
}

@include breakpoint(xs-only) {
  .q-footer {
    padding-left: 190px;
  }
}
